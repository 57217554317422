import { FC } from "react";
import HtmlToDocx from "../utils/HtmlToDocx";
import { Layout, Modal } from "antd";
import PageButton from "./Button";
import logo from "../assets/images/logo.png";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { Header } = Layout;
const { confirm } = Modal;

type HeaderProps = {
  togglePreview: () => void;
  isPreviewVisible: boolean;
  toggleEditingMode: () => void;
  isEditingMode: boolean;
  paragraphHTML: string;
  paragraphObject?: JSX.Element;
};

const PageHead: FC<HeaderProps> = ({
  togglePreview,
  isPreviewVisible,
  toggleEditingMode,
  paragraphHTML,
  isEditingMode
}) => {
  const showConfirm = () => {
    confirm({
      title: "UWAGA!",
      icon: <ExclamationCircleOutlined />,
      content:
        "Wyjście z trybu edycji spowoduje powrót do danych wprowadzonych w formularzu i utratę wszystkich zmian wprowadzonych w trybie edycji",
      onOk: toggleEditingMode
    });
  };

  return (
    <Header>
      <div className="logo">
        <img src={logo} alt="Logo" className="img" />
      </div>
      <div className="buttons">
        <PageButton
          text="Export DOCX"
          handleClick={() => {
            HtmlToDocx(paragraphHTML);
          }}
        />
        <PageButton text={`${isPreviewVisible ? "Ukryj" : "Pokaż"} podgląd`} handleClick={togglePreview} />
        {isPreviewVisible && (
          <PageButton
            text={isEditingMode ? "wyjście z trybu edycji" : "Tryb edycji"}
            handleClick={isEditingMode ? showConfirm : toggleEditingMode}
          />
        )}
      </div>
    </Header>
  );
};

export default PageHead;
