import React, { FC } from "react";
import Markdown from "markdown-to-jsx";
import ReactDOMServer from "react-dom/server";

const ParagraphTitle: FC = ({ children, ...props }) => (
  <div style={{ fontWeight: "bold", textAlign: "center", padding: "10px" }} {...props}>
    {children}
  </div>
);
const ContractList: FC = ({ children, ...props }) => (
  <ol style={{ padding: "10px", margin: "auto" }} {...props}>
    {children}
  </ol>
);

type ConvertToJSXType = (paragraphs: string) => JSX.Element;
type ConverttoHTMLType = (paragraphs: string) => string;

export const convertToJSX: ConvertToJSXType = paragraphs => (
  <div className="contractPreview" style={{ fontFamily: "Segoe UI" }}>
    <Markdown
      children={paragraphs}
      options={{
        overrides: {
          h1: {
            component: ParagraphTitle
          },
          ol: {
            component: ContractList
          }
        }
      }}
    />
  </div>
);

export const convertToHTML: ConverttoHTMLType = paragraphs =>
  ReactDOMServer.renderToStaticMarkup(convertToJSX(paragraphs));
