import { saveAs } from "file-saver";
import { asBlob } from "html-docx-js-typescript";

const HtmlToDocx = (htmlString: string) => {
  asBlob(htmlString)
    .then(data => {
      saveAs(data as Blob, "contract.docx");
    })
    .catch(err => {
      console.log(err);
    });
};

export default HtmlToDocx;
