import { FormValues } from "../components/AgreementForm/AgreementForm";
import { ParagraphData } from "../api/paragraphs";

type DataReplaceType = (formData?: FormValues, paragraphs?: ParagraphData[]) => string;
type ReplaceFunctionType = (text: string) => string;
export type SubjectFormsType = {
  mobileApp: string[];
  mobileWebApp: string[];
  website: string[];
  cooperation?: string[];
};

const subjectPolishForms: SubjectFormsType = {
  mobileApp: ["Aplikacja", "Aplikacji", "Aplikację", "aplikacji mobilnej"],
  mobileWebApp: ["Aplikacja", "Aplikacji", "Aplikację", "aplikacji mobilnej"],
  website: ["Strona", "Strony", "Stronę", "strony internetowej"]
};

export const dataReplace: DataReplaceType = (formData, paragraphs) => {
  const replaceSubjectTags: ReplaceFunctionType = text => {
    const subjectTag = /<subject-[0-9]*>/g;
    if (formData && paragraphs) {
      const { contractSubject }: { contractSubject: keyof SubjectFormsType } = formData;
      const selectedPolishForms = subjectPolishForms[contractSubject];
      if (selectedPolishForms) {
        const updatedContract = selectedPolishForms.reduce(
          (updatedText, selectedForm, index) =>
            updatedText.replaceAll(`<subject-${index}>`, selectedPolishForms[index]),
          text
        );
        return updatedContract;
      }
    }
    return text.replaceAll(subjectTag, "");
  };

  const replaceChunkTags: ReplaceFunctionType = text => {
    const chunkTag = /<chunk:[a-z][0-9]*_[0-9]*[a-z]*>/g;
    const removeRemainingTags = (contract: string) => contract.replaceAll(chunkTag, "");

    if (paragraphs && formData) {
      const formDataKeys = Object.keys(formData);
      const formDataValues = Object.values(formData);
      const checkboxFields = formDataKeys.filter((formKey, index) => formDataValues[index] === true);
      const selectFields = [formData.contractSubject, formData.priceType];

      const changingFields = selectFields.concat(checkboxFields);

      const updatedContract = changingFields.reduce((updatedForPrevKey, field) => {
        const replacedChunks = paragraphs.filter(chunk => chunk.type?.name === field);
        const searchedIds = replacedChunks.map(chunk => chunk.internalId);

        const updatedChunkForField = searchedIds.reduce(
          (updatedForPrevId, searchedId, searchedIdIndex) =>
            updatedForPrevId.replaceAll(`<chunk:${searchedId}>`, replacedChunks[searchedIdIndex].content),
          updatedForPrevKey
        );

        return updatedChunkForField;
      }, text);

      return removeRemainingTags(updatedContract);
    } else {
      return removeRemainingTags(text);
    }
  };

  if (paragraphs) {
    const generalInfo = paragraphs
      .filter(chunk => chunk.internalId === "00_00")
      .map(chunk => chunk.content)
      .toString();
    if (formData) {
      const formDataKeys = Object.keys(formData);
      const formDataValues = Object.values(formData).map(item => {
        if (item !== undefined) {
          return item.toString();
        } else {
          return "";
        }
      });
      const updatedContract = formDataKeys.reduce(
        (text, formKey, formKeyIndex) => text.replaceAll(`<${formKey}>`, formDataValues[formKeyIndex]),
        generalInfo
      );
      return replaceSubjectTags(replaceChunkTags(updatedContract));
    } else {
      return replaceSubjectTags(replaceChunkTags(generalInfo));
    }
  } else {
    return "";
  }
};
