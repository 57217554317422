import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

const PageFooter = () => (
  <div>
    <Footer></Footer>
  </div>
);

export default PageFooter;
