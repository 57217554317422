import { axiosClient } from "./axiosClient";

export interface CategoryObject {
  id: number;
  category: string;
}

export interface ParagraphData {
  id: number;
  internalId: string;
  name: string;
  content: string;
  type: { name?: string };
}

export async function getAllParagraphs(): Promise<ParagraphData[] | void> {
  try {
    const { data } = await axiosClient.get("/paragraphs");
    return data;
  } catch (error) {
    console.error(error);
  }
}
