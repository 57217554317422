import { FormFieldsType } from "../components/AgreementForm/AgreementForm";

export const formFields: FormFieldsType[] = [
  {
    type: "text",
    name: "contractTitle",
    label: "Tytuł umowy:"
  },
  {
    type: "select",
    name: "contractSubject",
    label: "Przedmiot umowy",
    selectOptions: [
      { value: "mobileApp", label: "aplikacja mobilna" },
      { value: "website", label: "strona internetowa" },
      { value: "mobileWebApp", label: "aplikacja mobilna i web" },
      { value: "cooperation", label: "kooperacja" }
    ],
    connectedFormFields: [
      {
        type: "checkbox",
        name: "android",
        label: "Android"
      },
      {
        type: "checkbox",
        name: "iOS",
        label: "iOS"
      },
      {
        type: "checkbox",
        name: "backend",
        label: "Backend"
      },
      {
        type: "checkbox",
        name: "hosting",
        label: "Hosting"
      }
    ]
  },
  {
    type: "date",
    name: "contractDate",
    label: "Data zawarcia umowy:"
  },
  {
    type: "text",
    name: "companyName",
    label: "Nazwa firmy:",
    options: {
      required: { value: true, message: "This field is required" },
      minLength: { value: 3, message: "At least 3 signs." }
    }
  },
  {
    type: "text",
    name: "representativeName",
    label: "Imię i nazwisko przedstawiciela:",
    options: {
      required: { value: true, message: "This field is required" },
      minLength: { value: 3, message: "At least 3 signs." }
    }
  },
  {
    type: "text",
    name: "street",
    label: "Ulica i nr domu/mieszkania:",
    options: {
      required: { value: true, message: "This field is required" },
      minLength: { value: 3, message: "At least 3 signs." }
    }
  },
  {
    type: "text",
    name: "city",
    label: "Miasto:",
    options: {
      required: { value: true, message: "This field is required" },
      minLength: { value: 3, message: "At least 3 signs." }
    }
  },
  {
    type: "text",
    name: "postalCode",
    label: "Kod pocztowy:",
    options: {
      required: { value: true, message: "This field is required" },
      minLength: { value: 3, message: "At least 3 signs." }
    }
  },
  {
    type: "text",
    name: "nip",
    label: "NIP:",
    options: {
      required: { value: true, message: "This field is required" },
      minLength: { value: 3, message: "At least 3 signs." }
    },
    secondInput: {
      type: "text",
      name: "country",
      label: "Kraj:"
    }
  },
  {
    type: "tel",
    name: "tel",
    label: "Nr telefonu:",
    options: {
      required: { value: true, message: "This field is required" },
      minLength: { value: 9, message: "At least 9 signs." }
    }
  },
  {
    type: "email",
    name: "email",
    label: "Email:",
    options: {
      required: { value: true, message: "This field is required" }
    }
  },
  {
    type: "checkbox",
    name: "eSignature",
    label: "Podpis elektroniczny"
  },
  {
    type: "checkbox",
    name: "isAllowedToPortfolio",
    label: "Czy jest dopuszczony do portfolio?"
  },
  {
    type: "date",
    name: "deadline",
    label: "Termin zakończenia:",
    options: {
      required: { value: true, message: "This field is required" }
    }
  },
  {
    type: "select",
    name: "priceType",
    label: "Typ rozliczenia:",
    options: {
      required: { value: true, message: "This field is required" }
    },
    selectOptions: [
      { value: "hourly", label: "godzinowo" },
      { value: "fixedPrice", label: "ustalona kwota" }
    ],
    connectedFormFields: [
      {
        type: "checkbox",
        name: "paymentInStages",
        label: "Rozliczenie etapami"
      },
      {
        type: "checkbox",
        name: "advancePayment",
        label: "Zaliczka"
      }
    ]
  }
];

export const hostingField: FormFieldsType = {
  type: "text",
  name: "hostingMonthlySubscription",
  label: "Hosting - miesięczna subskrypcja:"
};
