import * as React from "react";
import { Input, Select, Checkbox } from "antd";
import { Controller, FieldErrors } from "react-hook-form";
import { FormFieldsType } from "./AgreementForm/AgreementForm";

export interface FormFieldProps {
  control?: any;
  register: any;
  item: FormFieldsType;
  errors: FieldErrors;
  handleOnBlur: () => void;
  isEditingMode: boolean;
}

const FormField: React.FunctionComponent<FormFieldProps> = ({
  register,
  item,
  errors,
  control,
  children,
  handleOnBlur,
  isEditingMode
}) => {
  if (item.type === "select") {
    return (
      <div className="formField">
        <Controller
          name={item.name}
          control={control}
          render={({ field }) => (
            <>
              <Select
                style={{ width: 200 }}
                placeholder={item.label}
                {...field}
                options={item.selectOptions}
                onBlur={handleOnBlur}
                disabled={isEditingMode}
              />
              <br />
              {item.connectedFormFields?.map((formField, index) => {
                return (
                  formField.type === "checkbox" && (
                    <div key={index} className="connectedCheckboxes">
                      <Controller
                        name={formField.name}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            checked={field.value}
                            disabled={isEditingMode}
                            onMouseLeave={handleOnBlur}
                          >
                            {formField.label}
                          </Checkbox>
                        )}
                      />
                      {errors[formField.name] && <span className="error">{errors[formField.name].message}</span>}
                    </div>
                  )
                );
              })}
            </>
          )}
        />
        {children}
      </div>
    );
  } else if (item.type === "checkbox") {
    return (
      <div className="formField">
        <Controller
          name={item.name}
          control={control}
          render={({ field }) => (
            <Checkbox {...field} checked={field.value} disabled={isEditingMode} onMouseLeave={handleOnBlur}>
              {item.label}
            </Checkbox>
          )}
        />
      </div>
    );
  } else {
    return (
      <div className="formField">
        <Input
          type={item.type}
          id={`${item.name}`}
          {...register(item.name, item.options)}
          addonBefore={item.label}
          onBlur={handleOnBlur}
          disabled={isEditingMode}
        />
        {errors[item.name] && <span className="error">{errors[item.name].message}</span>}
        {item.secondInput && (
          <>
            <Input
              type={item.secondInput.type}
              id={`${item.secondInput.name}`}
              {...register(item.secondInput.name)}
              addonBefore={item.secondInput.label}
              onBlur={handleOnBlur}
              disabled={isEditingMode}
            />
            {errors[item.secondInput.name] && <span className="error">{errors[item.secondInput.name].message}</span>}
          </>
        )}
      </div>
    );
  }
};

export default FormField;
