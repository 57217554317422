import React, { FC } from "react";
import { Button } from "antd";

interface pageButtonProps {
  text: string;
  handleClick: () => void;
}

const PageButton: FC<pageButtonProps> = ({ text, handleClick }) => (
  <Button type="primary" onClick={handleClick}>
    {text}
  </Button>
);

export default PageButton;
