import React, { FC, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

import { convertToJSX } from "../utils/convertToHTML";

type PreviewProps = {
  paragraphText: string;
  paragraphHTML: string;
  isEditingMode: boolean;
  setParagraphHTML: (paragraph: string) => void;
};

export const Preview: FC<PreviewProps> = ({ paragraphText, paragraphHTML, isEditingMode, setParagraphHTML }) => {
  const convertedParagraphs = convertToJSX(paragraphText);
  const editorRef: any = useRef(null);

  return (
    <div>
      {isEditingMode ? (
        <Editor
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue={paragraphHTML}
          init={{
            height: "95vh",
            width: "50vw",
            menubar: true,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount"
            ],
            toolbar:
              "undo redo | formatselect | " +
              "bold italic backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px; text-align:justify }"
          }}
          onBlur={() => setParagraphHTML(editorRef.current.getContent())}
        />
      ) : (
        convertedParagraphs
      )}
    </div>
  );
};
