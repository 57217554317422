import { FormFieldsType } from "../components/AgreementForm/AgreementForm";

export const getStagesPriceFields = (i: number): FormFieldsType => ({
  type: "text",
  name: `stage${i + 1}` as const,
  label: `Opis etapu ${i + 1} :`,
  secondInput: {
    type: "number",
    name: `stagePrice${i + 1}` as const,
    label: `Cena za etap ${i + 1}:`
  }
});

export const getFixedPriceFields = (i: number): FormFieldsType => ({
  type: "text",
  name: `fixedSubject${i + 1}` as const,
  label: `${i + 1} Płatność za:`,
  secondInput: {
    type: "number",
    name: `fixedSubjectPrice${i + 1}` as const,
    label: `Cena :`
  }
});

export const fixedPriceFields: FormFieldsType[] = [
  {
    type: "number",
    name: "totalPrice",
    label: "Całkowita cena:"
  }
];

export const pricePerHourFields: FormFieldsType[] = [
  {
    type: "number",
    name: "hourlyRateSenior",
    label: "Stawka godzinowa Senior Developera:"
  },
  {
    type: "number",
    name: "hourlyRateMid",
    label: "Stawka godzinowa Mid Developera:"
  },
  {
    type: "number",
    name: "hourlyRateJuniorDev",
    label: "Stawka godzinowa Junior Developera:"
  },
  {
    type: "number",
    name: "hourlyRateJuniorQA",
    label: "Stawka godzinowa Junior QA:"
  }
];

export const advancePaymentField: FormFieldsType = {
  type: "number",
  name: "advancePaymentAmount",
  label: "Wielkość zaliczki:"
};

export const remainingAmountField: FormFieldsType = {
  type: "text",
  name: "remainingAmount",
  label: "Pozostałość do zapłaty"
};
