import React, { FC } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Button, Input } from "antd";
import "./AgreementForm.less";
import FormField from "../FormField";
import { formFields, hostingField } from "../../mocks/formFields";
import {
  getFixedPriceFields,
  pricePerHourFields,
  fixedPriceFields,
  advancePaymentField,
  getStagesPriceFields,
  remainingAmountField
} from "../../mocks/priceFields";
import { SubjectFormsType } from "../../utils/formDataReplace";

export interface AgreementFormProps {
  setFormData: (data: FormValues) => void;
  isEditingMode: boolean;
}
export interface SelectOptions {
  value: string;
  label: string;
}
export interface Options {
  required?: {
    value: boolean;
    message: string;
  };
  minLength?: {
    value: number;
    message: string;
  };
}
export interface FormFieldsType {
  type: string;
  name: string;
  label: string;
  options?: Options;
  secondInput?: {
    type: string;
    name: string;
    label: string;
    options?: Options;
  };
  selectOptions?: SelectOptions[];
  connectedFormFields?: { type: string; name: string; label: string; options?: Options }[];
}

export type FormValues = {
  contractTitle: string;
  contractSubject: keyof SubjectFormsType;
  android: boolean;
  iOS: boolean;
  backend: boolean;
  hosting: boolean;
  contractDate: string;
  companyName: string;
  representativeName: string;
  street: string;
  city: string;
  postalCode: string;
  nip: string;
  country: string;
  tel: number;
  email: string;
  eSignature: boolean;
  isAllowedToPortfolio: boolean;
  deadline: string;
  priceType: string;
  stages: Stage[];
  paymentInStages: boolean;
  advancePayment: boolean;
  totalPrice: number;
  hourlyRateSenior: number;
  hourlyRateMid: number;
  hourlyRateJuniorDev: number;
  hourlyRateJuniorQA: number;
  advancePaymentAmount: number;
  hostingMonthlySubscription: string;
  remainingAmount: number;
  fixedPrices: { fixedSubject: string; fixedSubjectPrice: number }[];
};

type Stage = {
  stage: string;
  stagePrice: number;
};

const defaultStagesValues = { stage: "", stagePrice: 0 };
const defaultFixedPricesValues = { fixedSubject: "", fixedSubjectPrice: 0 };

const AgreementForm: FC<AgreementFormProps> = ({ setFormData, isEditingMode }) => {
  const {
    register,
    formState: { errors },
    watch,
    control
  } = useForm<FormValues>({
    defaultValues: {
      stages: [defaultStagesValues],
      fixedPrices: [defaultFixedPricesValues]
    },
    mode: "onBlur"
  });
  const formValues = watch();

  const stages = useFieldArray({
    name: "stages",
    control
  });

  const fixedPrices = useFieldArray({
    name: "fixedPrices",
    control
  });

  return (
    <form className="agreementForm">
      {formFields.map((item, index) => {
        return (
          <FormField
            key={index}
            item={item}
            errors={errors}
            register={register}
            control={control}
            handleOnBlur={() => setFormData(formValues)}
            isEditingMode={isEditingMode}
          />
        );
      })}

      <div className="formField">
        {formValues.priceType === "fixedPrice" && (
          <div className="stages">
            {fixedPriceFields.map((field, i) => (
              <FormField
                register={register}
                control={control}
                errors={errors}
                key={i}
                item={field}
                handleOnBlur={() => {
                  setFormData(formValues);
                }}
                isEditingMode={isEditingMode}
              />
            ))}
            {fixedPrices.fields.map((field, i) => (
              <FormField
                register={register}
                control={control}
                errors={errors}
                key={i}
                item={getFixedPriceFields(i)}
                handleOnBlur={() => setFormData(formValues)}
                isEditingMode={isEditingMode}
              />
            ))}
            <Button
              className="stagesBtn"
              onClick={() => fixedPrices.append(defaultFixedPricesValues)}
              disabled={isEditingMode}
            >
              Dodaj przedmiot płatności
            </Button>
          </div>
        )}
        {formValues.priceType === "hourly" &&
          pricePerHourFields.map((rate, index) => (
            <FormField
              register={register}
              control={control}
              errors={errors}
              item={rate}
              handleOnBlur={() => setFormData(formValues)}
              isEditingMode={isEditingMode}
              key={index}
            />
          ))}

        {formValues.paymentInStages && (
          <div className="stages">
            {stages.fields.map((field, i) => (
              <FormField
                register={register}
                control={control}
                errors={errors}
                key={i}
                item={getStagesPriceFields(i)}
                handleOnBlur={() => setFormData(formValues)}
                isEditingMode={isEditingMode}
              />
            ))}
            <Button className="stagesBtn" onClick={() => stages.append(defaultStagesValues)} disabled={isEditingMode}>
              Dodaj etap płatności
            </Button>
          </div>
        )}
        {formValues.advancePayment && (
          <FormField
            register={register}
            control={control}
            errors={errors}
            item={advancePaymentField}
            handleOnBlur={() => setFormData(formValues)}
            isEditingMode={isEditingMode}
          />
        )}
        {formValues.hosting && (
          <FormField
            register={register}
            control={control}
            errors={errors}
            item={hostingField}
            handleOnBlur={() => setFormData(formValues)}
            isEditingMode={isEditingMode}
          />
        )}

        {formValues.advancePayment && formValues.priceType === "2" && (
          <Input
            type={remainingAmountField.type}
            id={remainingAmountField.name}
            {...register("remainingAmount", { value: formValues.totalPrice - formValues.advancePaymentAmount })}
            addonBefore={remainingAmountField.label}
            value={formValues.totalPrice - formValues.advancePaymentAmount}
            onBlur={() => setFormData(formValues)}
          />
        )}
      </div>
    </form>
  );
};

export default AgreementForm;
