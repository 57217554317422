import React, { FC, useState, useEffect } from "react";
import { Layout } from "antd";

import "./App.less";
import PageHead from "./components/Header";
import PageFooter from "./components/Footer";
import AgreementForm, { FormValues } from "./components/AgreementForm/AgreementForm";
import { ParagraphData, getAllParagraphs } from "./api/paragraphs";
import { dataReplace } from "./utils/formDataReplace";
import { convertToHTML } from "./utils/convertToHTML";
import { Preview } from "./components/Preview";

const App: FC = () => {
  const [formData, setFormData] = useState<FormValues>();
  const [paragraphs, setParagraphs] = useState<ParagraphData[]>([]);
  const [paragraphText, setParagraphText] = useState<string>("");
  const [paragraphHTML, setParagraphHTML] = useState("");
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [isEditingMode, setIsEditingMode] = useState(false);

  useEffect(() => {
    (async () => {
      const data = await getAllParagraphs();
      if (data) {
        setParagraphs(data);
      }
    })();
  }, []);

  const updateParagraphsContent = (data?: FormValues) => {
    const updatedContent = dataReplace(data, paragraphs);
    setParagraphText(updatedContent);
    setParagraphHTML(convertToHTML(updatedContent));
  };

  const togglePreview = () => {
    updateParagraphsContent(formData);
    setIsPreviewVisible(!isPreviewVisible);
  };

  const toggleEditingMode = () => {
    setIsEditingMode(!isEditingMode);
    if (isEditingMode) updateParagraphsContent(formData);
  };

  const { Content } = Layout;

  return (
    <div className="App">
      <PageHead
        togglePreview={togglePreview}
        isPreviewVisible={isPreviewVisible}
        toggleEditingMode={toggleEditingMode}
        isEditingMode={isEditingMode}
        paragraphHTML={paragraphHTML}
      />
      <Content>
        <AgreementForm
          setFormData={data => {
            setFormData(data);
            updateParagraphsContent(data);
          }}
          isEditingMode={isEditingMode}
        />
        {isPreviewVisible && (
          <Preview
            paragraphText={paragraphText}
            paragraphHTML={paragraphHTML}
            isEditingMode={isEditingMode}
            setParagraphHTML={setParagraphHTML}
          />
        )}
      </Content>
      <PageFooter />
    </div>
  );
};

export default App;
